import React, { useState } from 'react';
import { getBalance, sendTransaction } from '../api';

const Wallet = () => {
  const [address, setAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [to, setTo] = useState('');
  const [amount, setAmount] = useState('');
  const [privateKey, setPrivateKey] = useState('');

  const fetchBalance = async () => {
    const balance = await getBalance(address);
    setBalance(balance);
  };

  const handleSend = async () => {
    const receipt = await sendTransaction(address, to, amount, privateKey);
    console.log(receipt);
  };

  return (
    <div>
      <h2>Wallet</h2>
      <input type="text" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
      <button onClick={fetchBalance}>Get Balance</button>
      <p>Balance: {balance}</p>

      <input type="text" placeholder="To" value={to} onChange={(e) => setTo(e.target.value)} />
      <input type="text" placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
      <input type="text" placeholder="Private Key" value={privateKey} onChange={(e) => setPrivateKey(e.target.value)} />
      <button onClick={handleSend}>Send</button>
    </div>
  );
};

export default Wallet;
