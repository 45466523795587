import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Success.css';

const Success = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [theme, setTheme] = useState('light'); // Default theme
  const navigate = useNavigate();

  const saveOrderToFirebase = useCallback(async (orderData) => {
    try {
      const response = await fetch('http://localhost:5000/saveOrder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (e) {
      console.error('Error saving order to Firebase: ', e);
    }
  }, []);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`http://localhost:5000/checkout/${orderId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Order Details:', data); // Debugging line
        setOrderDetails(data);

        // Add additional order information for Firebase
        const orderData = {
          ...data,
          grandTotal: calculateGrandTotal(data.items),
        };
        saveOrderToFirebase(orderData);  // Save to Firebase
      } catch (error) {
        setError('Error fetching order details: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    } else {
      setError('OrderId is undefined');
      setLoading(false);
    }
  }, [orderId, saveOrderToFirebase]);

  const handleContinueShopping = () => {
    navigate('/');
  };

  const toggleOrderDetails = () => {
    setShowOrderDetails(!showOrderDetails);
  };

  const calculateGrandTotal = (items) => {
    if (!items || items.length === 0) {
      return 0;
    }
    return items.reduce((total, item) => total + (item.price || 0) * (item.quantity || 0), 0).toFixed(2);
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="success-container">
      <button className="toggle-button" onClick={toggleOrderDetails}>
        {showOrderDetails ? 'Hide Order Details' : 'Show Order Details'}
      </button>
      <button className="toggle-button" onClick={toggleTheme}>
        Toggle Theme
      </button>
      <h1>Order Successful!</h1>
      <p>Your order has been successfully placed.</p>
      <p>You will receive an email with the order ID shortly.</p>
      <button onClick={handleContinueShopping}>Continue Shopping</button>
      {showOrderDetails && (
        <div className="order-summary">
          <h2>Order Summary</h2>
          <p>Order ID: {orderId}</p>
          {orderDetails?.user && (
            <>
              <p>Name: {orderDetails.user.firstName} {orderDetails.user.lastName}</p>
              <p>Email: {orderDetails.user.email}</p>
              <p>Phone: {orderDetails.user.phone}</p>
              <p>Address: {orderDetails.user.address}</p>
              {orderDetails.user.useDifferentBilling && (
                <>
                  <p>Billing Address: {orderDetails.user.billingAddress}</p>
                  <p>Billing City: {orderDetails.user.billingCity}</p>
                  <p>Billing Postal Code: {orderDetails.user.billingPostalCode}</p>
                  <p>Billing Phone: {orderDetails.user.billingPhone}</p>
                </>
              )}
            </>
          )}
          <h3>Ordered Products:</h3>
          <ul>
            {orderDetails?.items?.map((item, index) => (
              <li key={index}>
                <h4 className="sidebar-product-name">{item.name}</h4>
                <p className="sidebar-product-description">{item.description}</p>
                <p className="sidebar-product-price">Price: ${item.price?.toFixed(2) || 'N/A'} per ounce</p>
                <p>Quantity: {item.quantity || 0}</p>
                <p>Total: ${(item.price || 0 * (item.quantity || 0)).toFixed(2)}</p>
              </li>
            ))}
          </ul>
          <h3>Grand Total: ${calculateGrandTotal(orderDetails?.items)}</h3>
        </div>
      )}
    </div>
  );
};

export default Success;
