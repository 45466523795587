import axios from 'axios';

const API_BASE_URL = 'http://localhost:5000'; // Change this to your backend URL if different

export const getBalance = async (address) => {
  const response = await axios.post(`${API_BASE_URL}/wallet/balance`, { address });
  return response.data.balance;
};

export const sendTransaction = async (from, to, amount, privateKey) => {
  const response = await axios.post(`${API_BASE_URL}/wallet/send`, { from, to, amount, privateKey });
  return response.data.receipt;
};
