import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Home.css';  // Assuming this imports the CSS file with all styles

import goldCoin from '../assets/goldCoin.png';
import silverCoin from '../assets/silverCoin.png';
import BRICS from '../assets/BRICS.png'; // Import your image assets
import SCO from '../assets/SCO.png';
import wallet from '../assets/wallet.png';
import blockexp from '../assets/blockexp.PNG';
import blockchain from '../assets/blockchain.png';

const Home = () => {
  const navigate = useNavigate();
  const [prices, setPrices] = useState({ gold: 0, silver: 0 });
  const [showSidebar, setShowSidebar] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const alphaVantageApiKey = 'IL77SOHODCPZQY01';
        const goldResponse = await axios.get(`https://www.alphavantage.co/query?function=CURRENCY_EXCHANGE_RATE&from_currency=XAU&to_currency=USD&apikey=${alphaVantageApiKey}`);
        const silverResponse = await axios.get(`https://www.alphavantage.co/query?function=CURRENCY_EXCHANGE_RATE&from_currency=XAG&to_currency=USD&apikey=${alphaVantageApiKey}`);

        const goldPrice = parseFloat(goldResponse.data['Realtime Currency Exchange Rate']['5. Exchange Rate']);
        const silverPrice = parseFloat(silverResponse.data['Realtime Currency Exchange Rate']['5. Exchange Rate']);

        setPrices({
          gold: goldPrice.toFixed(2),
          silver: silverPrice.toFixed(2),
        });
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchPrices();
    const intervalId = setInterval(fetchPrices, 10000); // fetch prices every 10 seconds
    return () => clearInterval(intervalId); // clear interval on component unmount
  }, []);

  const products = [
    {
      id: 1,
      name: "Gold Coin",
      image: goldCoin,
      price: parseFloat(prices.gold),
      description: "E-DNR BAIT7 CryptoGold Coin.",
    },
    {
      id: 2,
      name: "Silver Coin",
      image: silverCoin,
      price: parseFloat(prices.silver),
      description: "E-DHM BAIT10 CryptoSilver Coin.",
    },
  ];

  const addToCart = (product) => {
    const updatedCart = [...cartItems, { ...product, quantity: 1 }];
    setCartItems(updatedCart);
    setShowSidebar(true);
    calculateTotal(updatedCart);
  };

  const removeFromCart = (productToRemove) => {
    const updatedCart = cartItems.filter(item => item.id !== productToRemove.id);
    setCartItems(updatedCart);
    calculateTotal(updatedCart);
  };

  const adjustQuantity = (product, newQuantity) => {
    const updatedCart = cartItems.map(item => {
      if (item.id === product.id) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setCartItems(updatedCart);
    calculateTotal(updatedCart);
  };

  const calculateTotal = (items) => {
    const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotalPrice(total.toFixed(2));
  };

  const navigateToCheckout = () => {
    navigate('/checkout');
    setShowSidebar(false);
  };

  const continueShopping = () => {
    setShowSidebar(false);
  };

  return (
    <div className="home-container">
      <header className="price-bar">
        <div className="price-item">
          <img src={goldCoin} alt="Gold Coin" className="coin-image" />
          <h2>Gold Price: ${prices.gold} per ounce</h2>
        </div>
        <div className="price-item">
          <img src={silverCoin} alt="Silver Coin" className="coin-image" />
          <h2>Silver Price: ${prices.silver} per ounce</h2>
        </div>
      </header>
      <div className="home-content">
        <div className="home-text">
          <h1>Gold-Chain Technology and smart contract platform traces sourced Gold from Miner-to-Refiner -to-Vaults</h1>
          <p>Transforming Gold & Silver with Blockchain</p>
        </div>
        <div className="button-group">
          <button className="custom-button">Download BaitPay App</button>
          <button className="custom-button">Read Documentation</button>
        </div>
        <div className="banner">
          <Slider dots={true} infinite={true} speed={500} slidesToShow={1} slidesToScroll={1} autoplay={true} autoplaySpeed={2000}>
            <div className="slide">
              <img src={BRICS} alt="BRICS" className="banner-image" />
            </div>
            <div className="slide">
              <img src={SCO} alt="SCO" className="banner-image" />
            </div>
          </Slider>
        </div>
        
        <section className="products-section">
          <h2>Featured Products</h2>
          <div className="product-list">
            {products.map((product) => (
              <div className="product-item" key={product.id}>
                <img src={product.image} alt={product.name} className="product-image" />
                <div className="product-info">
                  <h3>{product.name}</h3>
                  <p>{product.description}</p>
                  <p>Price: ${product.price} per ounce</p>
                  <button onClick={() => addToCart(product)} className="add-to-cart-button">Add to Cart</button>
                </div>
              </div>
            ))}
          </div>
        </section>
       
<section className="image-container">
          <div className="image-item">
            <div className="text-container">
              
              <p className="image-description">
              </p>
              <img src={blockchain} alt="blockchain" className="container-image" />
            </div>
          </div>
          <div className="image-item">
            <div className="text-container">
              <p className="image-description">GOLD-CHAIN.TECH</p>
              <p className="image-description">
 
This Gold-Chain (blockchain) technology 
and smart contract platform traces sourced 
gold from mine-To-refiner-to-vault and then 
digitizes that gold into secure crypto gold 
coins for trade among the SCO, Brics, OIC, 
D8 countries.</p>
              
            
            </div>
            
          </div>

        </section>
        
        <section className="image-container">
          <div className="image-item">
            <div className="text-container">
              <p className="image-description">ADD GOLDCHAIN IN ANY CRYPTO WALLET</p>
              <p className="image-description">Low cost, forever</p>
              <p className="image-description">NETWORK: GOLDCHAIN</p>
              <p className="image-description">
                RPC: <a href="https://rpc.goldchain.tech" target="_blank" rel="noopener noreferrer">https://rpc.goldchain.tech</a>
              </p>
              <p className="image-description">CHAIN ID: 777771</p>
              <p className="image-description">SYMBOL: BAIT</p>
              <img src={wallet} alt="wallet" className="container-image" />
            </div>
          </div>
          <div className="image-item">
            <div className="text-container">
              <p className="image-description">BAIT Block Explorer</p>
              <p className="image-description">GoldChain is all about speedy transactions, with 400 millisecond block times. And as hardware gets faster, so does the network.</p>
              <p className="image-description">BAIT Block Explorer to verify chain activities.</p>
              <img src={blockexp} alt="block exp" className="container-image" />
            </div>
            
          </div>

        </section>
        <div className="container">
      <div className="card">
        <h2 className="card-title">BAIT Community</h2>
        <p className="card-text">BAIT-ul-Mal.org is an organized community of Sharia complaint trade instrument used widely across globe,we are goin to be in a DAO setup where every participants hold a right of authority in making decisions.</p>
        <button className="card-button">LEARN MORE</button>
      </div>
      <div className="card">
        <h2 className="card-title">Become a Validator</h2>
        <p className="card-text">Help secure the network by running decentralized infrastructure. Learn about operating a validator node.</p>
        <button className="card-button">GET STARTED</button>
      </div>
      <div className="card">
        <h2 className="card-title">Developer Resources</h2>
        <p className="card-text">See the get started guide, videos, tutorials, SDKs, reference implementations, and more.</p>
        <button className="card-button">START BUILDING</button>
      </div>
    </div>
    

    
    
        <div className={`sidebar ${showSidebar ? 'open' : ''}`}>
          <div className="sidebar-header">
            <h3 className="sidebar-title">Shopping Cart</h3>
            <span className="sidebar-close" onClick={() => setShowSidebar(false)}>×</span>
          </div>
          <div className="sidebar-content">
            {cartItems.map(item => (
              <div className="sidebar-product" key={item.id}>
                <img src={item.image} alt={item.name} className="sidebar-product-image" />
                <div className="sidebar-product-info">
                  <h4 className="sidebar-product-name">{item.name}</h4>
                  <p className="sidebar-product-description">{item.description}</p>
                  <p className="sidebar-product-price">Price: ${item.price.toFixed(2)} per ounce</p>
                  <div className="quantity-control">
                    <button onClick={() => adjustQuantity(item, item.quantity - 1)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => adjustQuantity(item, item.quantity + 1)}>+</button>
                  </div>
                  <button className="remove-button" onClick={() => removeFromCart(item)}>Remove</button>
                </div>
              </div>
              
            ))}
            <div className="sidebar-total">
              Total: ${totalPrice}
            </div>
            <div className="sidebar-buttons">
              <button className="checkout-button" onClick={navigateToCheckout}>Checkout</button>
              <button className="continue-shopping-button" onClick={continueShopping}>Continue Shopping</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
