// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD0f8rqHQJVGPjV0GzCkamCu7dC2iGCM4k",
  authDomain: "authentication-92b38.firebaseapp.com",
  projectId: "authentication-92b38",
  storageBucket: "authentication-92b38.appspot.com",
  messagingSenderId: "776683709752",
  appId: "1:776683709752:web:4db18c2994ce98c59f0dcd",
  measurementId: "G-E9HFB4MV6K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { app, auth, firestore };
