// src/components/Login.js

import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import backgroundImage from '../assets/backgroundImage.png';
import { Link } from 'react-router-dom';
import GoogleSignInAuth from './GoogleSignInAuth'; // Import GoogleSignInAuth component




const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { logInWithEmailPassword } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await logInWithEmailPassword(email, password);
      alert('Logged in successfully!');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Container>
      <BackgroundImage src={backgroundImage} alt="Background" />
      <FormContainer>
        <Form onSubmit={handleLogin}>
          <h2>Login</h2>
          <Label>Email</Label>
          <Input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
          <Label>Password</Label>
          <Input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
          <Button type="submit">Login</Button>
          {error && <Error>{error}</Error>}
          <SignUp>
            Don't have an account? <StyledLink to="/signup">Create an account</StyledLink>
          </SignUp>
          <GoogleSignInAuth /> {/* Include GoogleSignInAuth component */}
        </Form>
      </FormContainer>
    </Container>
  );
};


const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: right;
  align-items: right;
  height: 100vh;
  background-color: #f5f5f5;
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const FormContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 15px;
  width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  margin: 10px 0 5px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const Error = styled.p`
  color: red;
  margin-top: 10px;
  text-align: center;
`;

const SignUp = styled.p`
  margin-top: 10px;
  text-align: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #007bff; /* Blue color from Bootstrap */
  &:hover {
    text-decoration: underline;
  }
`;

export default Login;
