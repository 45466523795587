// src/components/Signup.js

import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

import GoogleSignInAuth from './GoogleSignInAuth'; // Import GoogleSignInAuth component

const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { signUpWithEmailPassword } = useAuth();

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await signUpWithEmailPassword(email, password);
      alert('User created successfully!');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={handleSignup}>
        <h2>Signup</h2>
        <Label>First Name</Label>
        <Input 
          type="text" 
          value={firstName} 
          onChange={(e) => setFirstName(e.target.value)} 
          required 
        />
        <Label>Last Name</Label>
        <Input 
          type="text" 
          value={lastName} 
          onChange={(e) => setLastName(e.target.value)} 
          required 
        />
        <Label>Contact Number</Label>
        <Input 
          type="text" 
          value={contactNumber} 
          onChange={(e) => setContactNumber(e.target.value)} 
          required 
        />
        <Label>Email</Label>
        <Input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
        <Label>Password</Label>
        <Input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          required 
        />
        <Label>Confirm Password</Label>
        <Input 
          type="password" 
          value={confirmPassword} 
          onChange={(e) => setConfirmPassword(e.target.value)} 
          required 
        />
        {error && <Error>{error}</Error>}
        <Button type="submit">Signup</Button>
        <Login>
          Already have an account? <StyledLink to="/login">Login</StyledLink>
        </Login>
        <GoogleSignInAuth /> {/* Include GoogleSignInAuth component */}
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: right;
  height: 100vh;
  background-color: #f5f5f5;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Label = styled.label`
  margin: 10px 0 5px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const Error = styled.p`
  color: red;
  margin-top: 10px;
  text-align: center;
`;

const Login = styled.p`
  margin-top: 10px;
  text-align: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #007bff; /* Blue color from Bootstrap */
  &:hover {
    text-decoration: underline;
  }
`;

export default Signup;
