import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../moralisLogo.svg'; // Adjust the path as needed

const Navbar = () => (
  <Nav>
    <LogoContainer>
      <img src={logo} alt="Blockchain Wallet Logo" />
    </LogoContainer>
    <LinkContainer>
      <StyledLink to="/">Home</StyledLink>
      <StyledAnchor href="http://localhost:8000">BlockExplorer</StyledAnchor>
      <StyledAnchor href="http://localhost:3003">Dex</StyledAnchor>
      
      <StyledLink to="/whitepaper">WhitePaper</StyledLink>
      <StyledButton href="https://baitpay.bait-coin.com">Connect BaitWallet</StyledButton>
      <StyledLinkButton to="/login">Login</StyledLinkButton>
      <StyledLinkButton to="/signup">Signup</StyledLinkButton>
    </LinkContainer>
  </Nav>
);

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #a18552;
  height: 57px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.5);
  transform-origin: left center;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;

  &:hover {
    color: #032b60;
  }
`;

const StyledAnchor = styled.a`
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;

  &:hover {
    color: #032b60;
  }
`;

const StyledButton = styled.a`
  color: white;
  background-color: #032b60;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const StyledLinkButton = styled(Link)`
  color: white;
  background-color: #032b60;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

export default Navbar;
