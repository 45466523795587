import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CheckoutForm.css'; // Import your CSS file for styling


const CheckoutForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    address: '',
    city: '',
    postalCode: '',
    phone: '',
    walletAddress: '',
    saveInfo: false,
    paymentMethod: '',
    useBillingAddress: true,
    creditCardNumber: '',
    expiryDate: '',
    cvv: '',
    useDifferentBilling: false, // Flag to toggle different billing address form
    billingFirstName: '',
    billingLastName: '',
    billingAddress: '',
    billingCity: '',
    billingPostalCode: '',
    billingPhone: '',
  });

  const countries = [
    'Select Country',
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'North Korea',
    'North Macedonia',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Korea',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City (Holy See)',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe'
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const toggleDifferentBilling = () => {
    setFormData({ ...formData, useDifferentBilling: !formData.useDifferentBilling });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      console.log('Sending request to:', 'http://localhost:5000/checkout');
      console.log('Request body:', JSON.stringify(formData));
  
      const response = await fetch('http://localhost:5000/checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      // Assuming the response indicates success with HTTP status 200
      const responseData = await response.json();
      const { orderId } = responseData; // Assuming your API returns orderId

      // Redirect to success page with orderId
      navigate(`/checkout/success/${orderId}`, { replace: true });
    } catch (error) {
      console.error('Error submitting order:', error.message);
  };
}

  return (
    <div className="checkout-form-container">
      <h2>Checkout</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required />
        </div>
        
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="country">Country</label>
          <select id="country" name="country" value={formData.country} onChange={handleChange} required>
            {countries.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <textarea id="address" name="address" value={formData.address} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="city">City</label>
          <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="postalCode">Postal Code</label>
          <input type="text" id="postalCode" name="postalCode" value={formData.postalCode} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="walletAddress">Wallet Address</label>
          <input type="text" id="walletAddress" name="walletAddress" value={formData.walletAddress} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>
            <input type="checkbox" name="saveInfo" checked={formData.saveInfo} onChange={handleChange} />
            Save this information for next time
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="paymentMethod">Payment Method</label>
          <select id="paymentMethod" name="paymentMethod" value={formData.paymentMethod} onChange={handleChange} required>
            <option value="">Select Payment Method</option>
            <option value="creditCard">Credit Card</option>
            <option value="cashonDelivery">Cash on Delivery</option>
          </select>
        </div>
        {formData.paymentMethod === 'creditCard' && (
          <>
            <div className="form-group">
              <label htmlFor="creditCardNumber">Credit Card Number</label>
              <input type="text" id="creditCardNumber" name="creditCardNumber" value={formData.creditCardNumber} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="expiryDate">Expiry Date</label>
              <input type="text" id="expiryDate" name="expiryDate" value={formData.expiryDate} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="cvv">CVV</label>
              <input type="text" id="cvv" name="cvv" value={formData.cvv} onChange={handleChange} required />
            </div>
          </>
        )}
        <div className="form-group">
          <label>
            <input type="checkbox" name="useDifferentBilling" checked={formData.useDifferentBilling} onChange={toggleDifferentBilling} />
            Use a different billing address
          </label>
        </div>
        {formData.useDifferentBilling && (
          <>
            <div className="form-group">
              <label htmlFor="billingFirstName">Billing First Name</label>
              <input type="text" id="billingFirstName" name="billingFirstName" value={formData.billingFirstName} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="billingLastName">Billing Last Name</label>
              <input type="text" id="billingLastName" name="billingLastName" value={formData.billingLastName} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="billingAddress">Billing Address</label>
              <textarea id="billingAddress" name="billingAddress" value={formData.billingAddress} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="billingCity">Billing City</label>
              <input type="text" id="billingCity" name="billingCity" value={formData.billingCity} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="billingPostalCode">Billing Postal Code</label>
              <input type="text" id="billingPostalCode" name="billingPostalCode" value={formData.billingPostalCode} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="billingPhone">Billing Phone</label>
              <input type="text" id="billingPhone" name="billingPhone" value={formData.billingPhone} onChange={handleChange} />
            </div>
          </>
        )}
       <button type="submit" className="submit-button">Place Order</button>
      </form>
    </div>
  );
};

export default CheckoutForm;





   

