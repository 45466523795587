// src/components/GoogleSignInAuth.js
import React from 'react';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';



const GoogleSignInAuth = () => {
  const { signInWithGoogle } = useAuth();

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      alert('Logged in with Google successfully!');
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <Button onClick={handleSignInWithGoogle}>
      Sign In with Google
    </Button>
  );
};

const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4285f4; /* Google blue */
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #357ae8; /* Darker shade on hover */
  }
`;

export default GoogleSignInAuth;
