import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Wallet from './components/Wallet';
import Home from './components/Home';
import Dex from './components/Dex';
import WhitePaper from './components/WhitePaper';
import Login from './components/Login';
import Signup from './components/Signup';
import Footer from './components/Footer';
import './App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { AuthProvider } from './contexts/AuthContext'; 
import CheckoutForm from './components/CheckoutForm';
import Success from './components/Success';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <AuthProvider> {/* Wrap your entire application with AuthProvider */}
      <Router>
        <div className="App">
          <Navbar />
          <button className="custom-button" onClick={toggleTheme}>
            Switch to {theme === 'light' ? 'Dark' : 'Light'} Mode
          </button>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/dex" element={<Dex />} />
            <Route path="/whitepaper" element={<WhitePaper />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/checkout" element={<CheckoutForm />} />
            <Route path="/success/:orderId" element={<Success />} />
            <Route path="/checkout/success/:orderId?" element={<Success />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
